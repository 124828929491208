<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ documentTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          id="cancel-button"
          :classProp="'secondary'"
          :isFullWidth="false"
          @vasionButtonClicked="cancel"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="save-attributes-button"
          class="last-btn"
          :classProp="'primary'"
          :isFullWidth="false"
          :clickActionCallback="goToSettings"
          :isDisabled="uploadedfilePath === '' || disableContinue"
        >
          Continue
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout toolbar-div">
        <div class="control-box">
          <md-button
            id="btnFirstPage"
            class="md-icon-button md-dense"
            title="First Page"
            :disabled="disablePreviousButtons"
            @click="goToFirstPage()"
          >
            <VasionFirstPageIcon :class="{ 'vasion-icon-disabled': disablePreviousButtons }" />
          </md-button>
          <md-button
            id="btnPreviousPage"
            class="md-icon-button md-dense"
            title="Previous Page"
            :disabled="disablePreviousButtons"
            @click="goToPreviousPage()"
          >
            <VasionPreviousPageIcon :class="{ 'vasion-icon-disabled': disablePreviousButtons }" />
          </md-button>
        </div>
        <VasionInput
          v-model="pageDisplay"
          :readOnly="true"
          inputType="blank-white"
          width="94"
          class="action-input"
        />
        <div class="control-box">
          <md-button
            id="btnNextPage"
            class="md-icon-button md-dense"
            title="Next Page"
            :disabled="disableNextButtons"
            @click="goToNextPage()"
          >
            <VasionNextPageIcon :class="{ 'vasion-icon-disabled': disableNextButtons }" />
          </md-button>
          <md-button
            id="btnLastPage"
            class="md-icon-button md-dense"
            title="Last Page"
            :disabled="disableNextButtons"
            @click="goToLastPage()"
          >
            <VasionLastPageIcon :class="{ 'vasion-icon-disabled': disableNextButtons }" />
          </md-button>
        </div>
        <div>
          <VasionButton
            id="template-file-button"
            class="browse-button panel-component-bottom-padding"
            :classProp="'primary'"
            :isDisabled="!isDocumentLoaded && document !== {}"
            @vasionButtonClicked="changeTemplateFile"
          >
            {{ activeDocument.isTemplate ? 'Change Template File' : 'Change File' }}
          </VasionButton>
        </div>
        <div> 
          <VasionInput
            v-model="totalZones"
            :readOnly="true"
            inputType="blank-white"
            width="200"
            style="height: 42px; padding-top: 5px;"
          />
        </div>
      </div>
      <div class="md-layout main-content">
        <div class="left-sidebar vasion-white-background">
          <span class="sidebar-signer">
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedSigner"
              :dataArray="signerList"
              :width="'100%'"
              title="SIGNER"
              placeholder="Assign New Zone To..."
              type="plain-list"
              displayName="name"
              valueName="value"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </span>
          <span class="sidebar-button-header">
            Fields
          </span>
          <span
            class="sidebar-button"
            :class="{ buttonDisabled: !isDocumentLoaded, buttonSelected: (annotationConfigStore.subType === 'signature') }"
            title="Signature"
            @click="addNewAnnotation(annotationConfig.signatureZone)"
          >
            <div class="button-icon">
              <VasionSignatureIcon />
            </div>
            Signature
          </span>
          <span
            class="sidebar-button"
            :class="{ buttonDisabled: !isDocumentLoaded, buttonSelected: (annotationConfigStore.subType === 'initial') }"
            title="Initials"
            @click="addNewAnnotation(annotationConfig.initialZone)"
          >
            <div class="button-icon">
              <VasionInitialsIcon />
            </div>
            Initials
          </span>
          <span
            class="sidebar-button"
            :class="{ buttonDisabled: !isDocumentLoaded, buttonSelected: (annotationConfigStore.subType === 'timestamp') }"
            title="Date / Time"
            @click="addNewAnnotation(annotationConfig.dateZone)"
          >
            <div class="button-icon">
              <VasionAccessTimeIcon />
            </div>
            Date / Time
          </span>
          <span
            class="sidebar-button"
            :class="{ buttonDisabled: !isDocumentLoaded, buttonSelected: (annotationConfigStore.subType === 'text') }"
            title="Text"
            @click="addNewAnnotation(annotationConfig.textZone)"
          >
            <div class="button-icon">
              <VasionTextFieldIcon />
            </div>
            Text
          </span>
          <span
            class="sidebar-button"
            :class="{ buttonDisabled: !isDocumentLoaded, buttonSelected: (annotationConfigStore.subType === 'checkbox') }"
            title="Checkbox"
            @click="addNewAnnotation(annotationConfig.checkbox)"
          >
            <div class="button-icon">
              <VasionCheckBoxCheckedIcon />
            </div>
            Checkbox
          </span>
        </div>
        <div class="md-layout-item middle-section">
          <div v-if="!isDocumentLoaded" class="document-upload">
            <div class="upload-container">
              <div class="file-pond-block-wrapper">
                <file-pond
                  ref="pond"
                  class="new-document"
                  :server="serverOptions"
                  :label-idle="filePondLabel"
                />
              </div>
            </div>
          </div>
          <div v-else class="document-loaded">
            <DocumentImage
              v-if="uploadedfilePath && uploadedfilePath !== ''"
              ref="mainImage"
              :key="currentPageNumber"
              :documentID="0"
              :pageNumber="currentPageNumber"
              :width="1210"
              :height="1500"
              :zoomScale="0.39"
              :largeDefaultLoadSize="true"
              :context="'SignatureApp'"
              :imageEndpoint="'document/getFileImageURL'"
              :filePath="uploadedfilePath"
              @annotationDeleted="annotationDeleted"
              @annotationUpdate="annotationUpdate"
              @annotationCreated="annotationCreated"
            />
          </div>
          <div v-if="uploadedfilePath && uploadedfilePath !== ''" class="zoom-box">
            <div class="zoom-symbol" @click="zoomOut">
              <VasionRemoveIcon />
            </div>
            <div class="drop-list-zoom" />
            <div class="zoom-symbol" @click="zoomIn">
              <VasionAddIcon />
            </div>
          </div>
        </div>
        <div class="right-sidebar">
          <div v-if="showAssociateUserPanel && (addedUserList.length > 0 || addedRoles.length > 0)" class="add-users">
            <div id="zone-details-title">
              Zone Details
            </div>
            <VasionInput
              v-model="displayZoneType"
              class="row-field"
              inputType="top-white"
              title="ZONE TYPE"
              width="280"
              :readOnly="true"
              style="margin-bottom: 16px;"
            />
            <VasionDropList
              v-slot="slotItem"
              v-model="assignedUser"
              :dataArray="addedUserListForDropList"
              :title="zoneLabel('ASSIGN USER')"
              placeholder="Select User..."
              type="plain-list"
              displayName="name"
              valueName="value"
              width="280"
              style="margin-bottom: 16px;"
              @inputKeyDown="setDeleteOnInput"
              @inputFocusOut="clearDeleteOnInput"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-slot="slotItem"
              v-model="assignedRole"
              class="row-field"
              :dataArray="addedRoles"
              width="280"
              :title="zoneLabel('ASSIGN ROLE')"
              placeholder="Select Role..."
              type="plain-list"
              style="margin-bottom: 16px;"
              displayName="value"
              valueName="value"
            >
              {{ slotItem.item.value }}
            </VasionDropList>
            <VasionCheckbox
              v-if="associateZoneConfig.subType !== 'checkbox'"
              id="bottom-align"
              class="panel-component-bottom-padding"
              name="bottom-align"
              :checked="localBottomAlign"
              :isDisabled="!assignedRole && !assignedUser"
              @change="toggleCheckbox('localBottomAlign')"
            >
              Bottom Align
            </VasionCheckbox>
            <VasionCheckbox
              v-if="associateZoneConfig.subType === 'timestamp'"
              class="panel-component-bottom-padding"
              id="include-time-checkbox"
              name="include-time-checkbox"
              :checked="localIncludeTime"
              :isDisabled="!assignedRole && !assignedUser"
              @change="toggleCheckbox('localIncludeTime')"
            >
              Include Time
            </VasionCheckbox>
            <VasionCheckbox
              class="-component-bottom-papaneldding"
              id="include-required-checkbox"
              name="include-required-checkbox"
              :checked="localIsRequired"
              :isDisabled="!assignedRole && !assignedUser"
              @change="toggleCheckbox('localIsRequired')"
            >
              Required
            </VasionCheckbox>
          </div>
          <div v-else>
            <div class="add-users divider">
              <div v-if="!activeDocument.isTemplate">
                <VasionInput
                  id="bcc-text-box"
                  v-model="addUsers"
                  v-debounce:200ms="userSearchList"
                  title="ADD SIGNERS"
                  name="bcc-text-box"
                  placeholder="Add with Email / Username"
                  inputType="top-white"
                  width="280"
                  class="bottom-margin inputWidth"
                  @keydown="setDeleteOnInput"
                  @focusout="clearDeleteOnInput"
                />
                <div v-if="showSearchList" class="user-search">
                  <div v-if="localUserList.length > 0" class="user-list-container">
                    <div
                      v-for="(user, index) in localUserList"
                      :key="index"
                      class="user-list-item"
                      @click="addToList('added', user)"
                    >
                      <div class="user-list-item-name">
                        <strong> {{ user.FullName }} </strong><br>
                        <div v-if="user.Email === ''">
                          No registered Email
                        </div>
                        <div v-else>
                          {{ user.Email }}
                        </div>
                      </div>
                      <div class="user-list-btn">
                        <VasionAddCircleIcon fill="#ff4702" />
                      </div>
                    </div>
                  </div>
                  <div v-else class="user-list-container">
                    <div class="empty-add-signers-container">
                      <VasionUserIcon />
                      <div class="users-heading" style="margin-top: 10px;">
                        User not found
                      </div>
                      <div class="users-subheading">
                        Would you like to add this email as a signer for this document?
                      </div>
                      <VasionButton
                        id="add-signer-button"
                        style="margin-top: 10px;"
                        :classProp="'secondary-grey'"
                        :isFullWidth="false"
                        @vasionButtonClicked="showAddSigner = true"
                      >
                        <div id="add-signer-icon-and-text">
                          <VasionAddIcon />
                          Add Signer
                        </div>
                      </VasionButton>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <VasionInput
                  id="add-role-text-box"
                  v-model="addRoleInput"
                  title="ADD ROLE"
                  name="add-role-text-box"
                  placeholder="Enter Role Name..."
                  inputType="top-white"
                  width="280"
                  class="bottom-margin inputWidth"
                />
                <md-button
                  id="add-role-button"
                  class="md-button"
                  title="Add"
                  :disabled="addRoleButtonDisabled"
                  @click.stop="addRole"
                >
                  Add
                </md-button>
              </div>
            </div>
            <div class="added-users">
              <div v-if="!activeDocument.isTemplate">
                <VasionCheckbox
                  id="two-factor-auth"
                  name="two-factor-auth"
                  style="margin-bottom: 20px;"
                  class="export-checkbox align-with-inputs"
                  :checked="twoFactorAuth"
                  @change="toggleCheckbox('twoFactorAuth')"
                >
                  Require two-factor authentication
                </VasionCheckbox>
                <VasionCheckbox
                  id="separate-copy-for-each-signer"
                  name="separate-copy-for-each-signer"
                  style="margin-bottom: 20px;"
                  class="export-checkbox align-with-inputs"
                  :checked="separateCopyForSigners"
                  @change="toggleCheckbox('separateCopyForSigners')"
                >
                  Separate Copy for each Signer
                </VasionCheckbox>
                <div class="added-users-title">
                  ADDED SIGNERS
                </div>
                <div v-if="localAddedUserList.length === 0" class="added-users-content">
                  <span>
                    <VasionGroupGrayIcon class="added-users-icon" />
                  </span>
                  <div class="users-heading">
                    You haven't added any users.
                  </div>
                  <div class="users-subheading">
                    Enter an email or username to add users to this document.
                  </div>
                </div>
                <div v-else class="added-users-content">
                  <div class="added-users-container">
                    <div v-for="(user, index) in localAddedUserList" :key="index" class="added-users-item">
                      <div class="added-users-info added-users-text">
                        <strong> {{ user.FullName }} </strong><br>
                        <div v-if="user.Email === ''" class="added-users-text">
                          No registered Email
                        </div>
                        <div v-else class="added-users-text">
                          {{ user.Email }}
                        </div>
                      </div>
                      <div class="added-users-btn">
                        <md-button class="md-icon-button md-dense" title="Remove" @click.stop="ellipsisOpen($event, user)">
                          <VasionEllipsisIcon />
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="added-users-title">
                  ADDED ROLES
                </div>
                <div v-if="addedRoles.length === 0" class="added-users-content">
                  <span>
                    <VasionGroupGrayIcon class="added-users-icon" />
                  </span>
                  <div class="users-heading">
                    You haven’t added any roles.
                  </div>
                  <div class="users-subheading">
                    Add a role to the template to assign it to zones to be signed.
                  </div>
                </div>
                <div v-else class="added-users-content">
                  <div class="added-users-container">
                    <div v-for="(role, index) in addedRoles" :key="index" class="added-users-item">
                      <div class="added-users-info added-users-text">
                        <strong> {{ role.value }} </strong><br>
                      </div>
                      <div class="added-users-btn">
                        <md-button class="md-icon-button md-dense" title="Remove" @click.stop="ellipsisOpen($event, role.value)">
                          <VasionEllipsisIcon />
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VasionEllipsisMenu
      v-if="showEllipsisMenu"
      v-click-outside="ellipsisClose"
      :buttonConfig="ellipsisButtonConfig"
      :rowItem="ellipsisRowItem"
      :style="ellipsisMenuTopStyle"
      @ellipsis-button-clicked="ellipsisButtonClicked"
      @close="ellipsisClose"
    />
    <VasionGeneralModal
      id="add-external-signer-modal"
      :rejectButtonText="'Cancel'"
      :confirmButtonText="confirmButtonOnAddSignersModal"
      :modalType="'slot'"
      :confirmButtonDisabled="!isAddSignerValidated"
      modalTitle="Add Signer"
      :sync="showAddSigner"
      @confirmButtonClick="addSigner()"
      @noButtonClick="cancelAddSigner()"
    >
      <div id="add-external-signer-container">
        <VasionInput
          v-model="addSignerFirstName"
          :required="true"
          style="margin-top: 29px;"
          inputType="top-white"
          title="First Name"
        />
        <VasionInput
          v-model="addSignerLastName"
          :required="true"
          class="add-signer-modal-spacer"
          inputType="top-white"
          title="Last Name"
        />
        <VasionInput
          v-model="addUsers"
          :required="true"
          class="add-signer-modal-spacer"
          inputType="top-white"
          specialType="email"
          title="Email"
          @notValid="isEmailValid = false"
          @isValid="isEmailValid = true"
        />
        <VasionInput
          v-model="addSignerPhoneNumber"
          :required="twoFactorAuth"
          class="add-signer-modal-spacer"
          inputType="top-white"
          title="Phone Number"
          type="tel"
        />
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="edit-role-dialog"
      rejectButtonText="Cancel"
      confirmButtonText="Update"
      :modalType="'slot'"
      :confirmButtonDisabled="disableUpdateRole"
      modalTitle="Edit Role"
      :sync="showEditRoleModal"
      @confirmButtonClick="updateRole()"
      @noButtonClick="showEditRoleModal = false"
    >
      <VasionInput
        v-model="editRoleInput"
        inputType="blank-white"
        width="347"
        style="margin: 29px 0px;"
      />
    </VasionGeneralModal>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionGeneralModal
      id="change-template-confirmation-div"
      :confirmButtonText="'CONFIRM'"
      :rejectButtonText="'CANCEL'"
      :modalType="'slot'"
      :sync="showReplaceFileDialog"
      @confirmButtonClick="replaceFileOKClick()"
      @noButtonClick="toggleReplaceFileDialog()"
    >
      <div v-if="isDirty" class="vasion-error-text">
        WARNING: Any changes you have not saved will be lost
      </div>
      <div>
        <md-radio
          v-model="clearZonesOnChangeFile"
          :value="false"
        >
          Replace file only (keep existing zones)
        </md-radio>
      </div>
      <div>
        <md-radio
          v-model="clearZonesOnChangeFile"
          :value="true"
        >
          Replace file and clear zones
        </md-radio>
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="change-object-confirmation-div"
      :confirmButtonText="'REPLACE'"
      :rejectButtonText="'CANCEL'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="replaceMessage + '\n Would you like to proceed and force replacing the file?'"
      :sync="showForceReplaceDialog"
      @confirmButtonClick="replaceTemplateModalOKClick()"
      @noButtonClick="toggleForceReplaceDialog()"
    />

    <md-dialog id="upload-new-file-dialog" class="dialog" :md-active.sync="showUploadDialog" :md-click-outside-to-close="false">
      <div class="upload-container">
        <div class="file-pond-block-wrapper">
          <file-pond
            ref="pond"
            class="new-document"
            :server="serverOptions"
            :label-idle="filePondLabel"
          />
        </div>
      </div>
      <div class="align-right">
        <VasionButton
          id="close-upload-dialog"
          :isFullWidth="false"
          :classProp="'secondary'"
          @vasionButtonClicked="toggleUploadDialog"
        >
          CLOSE
        </VasionButton>
      </div>
    </md-dialog>

    <VasionSnackbar
      id="document-uploaded-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue';
import { toBase64 } from '@/store/helperModules/storage.module'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'NewDocument',
  components: {
    DocumentImage,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.activeDocument.isTemplate) this.$store.dispatch('digitalSignature/setKeepDataOnReturn', false)
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
     vm.prevRoute = from
   })
  },
  data: function () {
    return {
      addedRoles: [],
      addRoleInput: '',
      addSignerFirstName: '',
      addSignerLastName: '',
      addSignerPhoneNumber: '',
      addUsers: '',
      allZones: 0,
      annotationConfig: {
        signatureZone: { type: 'signatureZone', color: '', subType: 'signature', bottomAlign: true, isRequired: true },
        initialZone: { type: 'signatureZone', color: '', subType: 'initial', bottomAlign: true, isRequired: true },
        dateZone: { type: 'signatureZone', color: '', subType: 'timestamp', bottomAlign: true, isRequired: true },
        textZone: { type: 'signatureZone', color: '', subType: 'text', bottomAlign: true, isRequired: true },
        checkbox: { type: 'signatureZone', color: '', subType: 'checkbox', bottomAlign: true, isRequired: true },
      },
      assignedRole: '',
      assignedUser: '',
      assignedZonesSignatureList: [],
      assignedUserSignatureList: [],
      changedCurrentPage: false,
      checkboxRequired: false,
      clearZonesOnChangeFile: false,
      currentEditedEmail: '',
      currentPageNumber: 1,
      document: {},
      documentFileName: '',
      editingUser: false,
      editTemplateId: 0,
      editRoleInput: '',
      editRoleValue: '',
      ellipsisMenuTopStyle: '',
      ellipsisRowItem: 0,
      isDirty: false,
      isDocumentLoaded: false,
      isEmailValid: false,
      isReplacingFile: false,
      localBottomAlign: false,
      localAddedUserList: [],
      localIncludeTime: false,
      localIsRequired: true,
      localUserList: [],
      newObjectSelected: false,
      prevRoute: null,
      replacementDocumentGUID: null,
      replaceMessage: '',
      selectedSigner: {},
      separateCopyForSigners: false,
      serverOptions: { process: this.processHandler },
      showAddSigner: false,
      showAssociateUserPanel: false,
      showEditRoleModal: false,
      showEllipsisMenu: false,
      showLeaveDialog: false,
      showForceReplaceDialog: false,
      showReplaceFileDialog: false,
      showSearchList: false,
      showSnackbarBool: false,
      showUploadDialog: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      totalPages: 1,
      twoFactorAuth: false,
      uploadedFileFieldValue: '',
      uploadedFileName: '',
      uploadedfilePath: '',
      uploadedFileString: '',
      userList: [],
    }
  },
  computed: {
    activeDocument() { return this.$store.state.digitalSignature.activeDocument },
    activeSaveAsTemplateButton() { return this.editTemplateId <= 0 },
    addedUserList() { return this.$store.state.digitalSignature.addedUsersList },
    addedUserListForDropList() {
      return this.addedUserList.map(u => {
        return {
          name: u.FullName,
          value: u.UserId,
        }
      })
    },
    addRoleButtonDisabled() { return this.addRoleInput === '' || this.addRoleInput.trim() === '' || this.addedRoles.some(role => role.value === this.addRoleInput) },
    annotationConfigStore() { return this.$store.state.document.annotation },
    annotations() { return this.$store.state.workflow.signatureZoneSaveObject },
    associateZoneConfig() { return this.$store.state.digitalSignature.associateUserConfig; },
    confirmButtonOnAddSignersModal() { return this.editingUser ? 'UPDATE' : 'ADD' },
    disableContinue() {
      const isTemplateBeingEdited = this.activeDocument.signatureDocumentTemplateID > 0
      if (this.activeDocument.isTemplate && !isTemplateBeingEdited && !this.assignedZonesSignatureList.find(e => e.isRequired)) {
        return true
      }
      else if (!this.activeDocument.isTemplate && !isTemplateBeingEdited && !this.assignedUserSignatureList.find(e => e.isRequired)) {
        return true
      }

      if (this.twoFactorAuth && this.localAddedUserList?.length > 0) {
        if (this.localAddedUserList.some(u => u.PhoneNumber === '' || u.PhoneNumber === null)) {
          return true
        }
      }
      if (this.separateCopyForSigners && this.localAddedUserList?.length > 0) {
        return false
      }
      if (this.activeDocument.isTemplate && this.assignedZonesSignatureList.length !== 0) {
          return this.assignedZonesSignatureList.length != this.allZones
      } 
      if (this.assignedUserSignatureList.length !== 0){
          return this.assignedUserSignatureList.length != this.allZones
      }else{
        return true
      }
    },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    disableUpdateRole() { return this.editRoleInput === '' || this.addedRoles.some(role => role.value === this.editRoleInput) },
    displayZoneType() {
      let zoneType = ''
      if (this.associateZoneConfig && this.associateZoneConfig.subType && this.associateZoneConfig.subType !== '') {
        zoneType = this.associateZoneConfig.subType
        if (zoneType === 'timestamp') {
          zoneType = 'date / time'
        }
      }

      return zoneType
    },
    documentTitle() { return this.uploadedFileName !== '' ? this.uploadedFileName : 'Untitled Document' },
    ellipsisButtonConfig() {
      const ellipsisButtonConfig = {}
        const userButtons = [
          {
            id: 'edit-signer',
            name: 'Edit Signer',
            icon: 'VasionEditIcon',
          },
          {
            id: 'remove-signer',
            name: 'Remove from Document',
            icon: 'VasionDeleteIcon',
          },
        ]
        const roleButtons = [
          {
            id: 'edit-role',
            name: 'Edit Role',
            icon: 'VasionEditIcon',
          },
          {
            id: 'remove-role',
            name: 'Remove from Document',
            icon: 'VasionDeleteIcon',
          },
        ]
      if (this.activeDocument.isTemplate) {
        ellipsisButtonConfig.buttons = roleButtons
      } else {
        ellipsisButtonConfig.buttons = userButtons
      }
      return ellipsisButtonConfig
    },
    filePondLabel() {
      return `<span class="filepond-drag">Drag TIFF or PDF File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Upload New Document</span>`
    },
    propertiesAnnotationsArray() { return this.$store.state.digitalSignature.propertiesAnnotationsArray },
    isAddSignerValidated() { return this.addSignerFirstName && this.addSignerLastName && this.isEmailValid && !this.isDuplicateEmail && (!this.twoFactorAuth || this.addSignerPhoneNumber) },
    isDuplicateEmail() { return this.userList.some(user => { return user.Email === this.addUsers }) },
    keepDataOnReturn() { return this.$store.state.digitalSignature.keepDataOnReturn },
    pageDisplay: {
      get: function () {
          return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    separateCopyForSignersFromStore() { return this.$store.state.digitalSignature.separateCopyForSigners },
    signerList() {
      let signers = []
      if (this.addedUserList.length > 0) {
        signers = this.addedUserList.map(u => {
          return {
            name: u.FullName,
            value: u.UserId,
          }
        })
      }
      if (this.addedRoles.length > 0) {
        this.addedRoles.forEach((role, i) => {
          signers.push({
            name: role.value,
            value: i + 1
          })
        })
      }
      signers.unshift({ name: 'Unassigned', value: 0 })
      return signers
    },
    totalZones() { return "Number of Zones: " + this.allZones },
    twoFactorAuthFromStore() { return this.$store.state.digitalSignature.twoFactorAuth },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    assignedUser: function () {
      if (!this.newObjectSelected) {
        this.saveAssignedUser()
      }
    },
    assignedRole: function () {
      if (!this.newObjectSelected) {
        this.saveAssignedRole()
      }
    },
    associateZoneConfig: function () {
      this.showAssociateUserPanel = this.associateZoneConfig.showSetUserPanel
      if (this.showAssociateUserPanel) {
        let foundElement = null
        if (this.propertiesAnnotationsArray) {
          foundElement = this.propertiesAnnotationsArray.find(e => e.UniqueID == this.associateZoneConfig.uniqueId)
        }

        if (foundElement) {
          this.localIncludeTime = foundElement.includeTime
          this.localBottomAlign = foundElement.bottomAlign
          this.localIsRequired = foundElement.isRequired
        } else {
          this.localIncludeTime = false
          this.localBottomAlign = true
          this.localIsRequired = true
        }

        if (this.activeDocument.isTemplate) {
          this.loadAssignedRole()
        } else {
          this.loadAssignedUser()
        }
      } else if (this.activeDocument.isTemplate) {
        this.assignedRole = ''
      } else {
        this.assignedUser = ''
      } 
    },
    currentPageNumber: function () {
      this.changedCurrentPage = true
      if (this.uploadedFileName !== '' && this.uploadedfilePath !== '' && this.annotations) {
        this.updateActiveDocument()
        this.updateDocumentImage()
      }
    },
    localBottomAlign: function() {
      if (!this.associateZoneConfig) {
        return
      }

      let zone = undefined
      if (this.activeDocument.isTemplate) {
        zone = this.activeDocument.assignedZonesSignatureList.find(e => e.objectId == this.associateZoneConfig.uniqueId)
      } else {
        zone = this.activeDocument.assignedUserSignatureList.find(e => e.objectId == this.associateZoneConfig.uniqueId)
      }
      if (zone != undefined) {
        zone.bottomAlign = this.localBottomAlign
      }
      
      const payload = {
        UniqueID: this.associateZoneConfig.uniqueId,
        includeTime: this.localIncludeTime,
        bottomAlign: this.localBottomAlign,
        isRequired: this.localIsRequired,
        saveIfDifferent: true,
      }

      this.$store.dispatch('digitalSignature/updateElementInPropertiesAnnotationsArray', payload)
    },
    localIncludeTime: function () {
      if (!this.associateZoneConfig || this.associateZoneConfig.subType != 'timestamp') {
        return
      }

      const payload = {
        UniqueID: this.associateZoneConfig.uniqueId,
        includeTime: this.localIncludeTime,
        bottomAlign: this.localBottomAlign,
        isRequired: this.localIsRequired,
        saveIfDifferent: true,
      }

      this.$store.dispatch('digitalSignature/updateElementInPropertiesAnnotationsArray', payload)
    },
    localIsRequired: function() {
      if (!this.associateZoneConfig) {
        return
      }

      let zone = undefined
      if (this.activeDocument.isTemplate) {
        zone = this.activeDocument.assignedZonesSignatureList.find(e => e.objectId == this.associateZoneConfig.uniqueId)
      } else {
        zone = this.activeDocument.assignedUserSignatureList.find(e => e.objectId == this.associateZoneConfig.uniqueId)
      }
      if (zone != undefined) {
        zone.isRequired = this.localIsRequired
      }
      
      const payload = {
        UniqueID: this.associateZoneConfig.uniqueId,
        includeTime: this.localIncludeTime,
        bottomAlign: this.localBottomAlign,
        isRequired: this.localIsRequired,
        saveIfDifferent: true,
      }

      this.$store.dispatch('digitalSignature/updateElementInPropertiesAnnotationsArray', payload)
    },
    saveAnnotationsToggle() { return this.$store.state.digitalSignature.saveAnnotationsToggle },
    separateCopyForSigners: function () { this.$store.dispatch('digitalSignature/setSeparateCopyForSigners', this.separateCopyForSigners) },
    showAddSigner: async function () { this.isEmailValid = Boolean(await this.$store.dispatch('common/validateEmail', this.addUsers))},
    twoFactorAuth: function () { this.$store.dispatch('digitalSignature/setTwoFactorAuth', this.twoFactorAuth) },
  },
  async created() {
    this.$store.dispatch('digitalSignature/setDeleteOnInput', false)
    if (!this.keepDataOnReturn) this.$store.dispatch('digitalSignature/setAddedUsersList', [])
    this.userList = await this.$store.dispatch('security/getUserListIncludeExternal')
    this.userList = this.userList.filter(u => {
      return !this.addedUserList.find(au => au.UserId === u.UserId)
    })
    this.localUserList = this.userList

    if (this.activeDocument?.editTemplateId !== undefined) {
      this.editTemplateId = this.activeDocument.editTemplateId
    }
    this.activeDocument.assignedZonesSignatureList?.forEach(sigZone => {
      if (!this.addedRoles.some(x => x.value === sigZone.sigDocTemplateRoleName)) {
        this.addedRoles.push({value: sigZone.sigDocTemplateRoleName})
      }
    })

    if (this.prevRoute && this.prevRoute.name === 'TheSignatureTemplates') {
      this.updateDocumentImage()
    }
    if (this.prevRoute && this.prevRoute.name === 'SignatureEditDocument') {
      this.separateCopyForSigners = this.separateCopyForSignersFromStore
      this.twoFactorAuth = this.twoFactorAuthFromStore
      this.localAddedUserList = this.addedUserList
      if (this.activeDocument?.assignedUserSignatureList?.length) this.assignedUserSignatureList = this.activeDocument.assignedUserSignatureList
      if (this.activeDocument?.assignedZonesSignatureList?.length) this.assignedZonesSignatureList = [...this.activeDocument.assignedZonesSignatureList]
      if (this.assignedUserSignatureList.length) this.allZones = this.assignedUserSignatureList.length
      if (this.assignedZonesSignatureList.length) this.allZones = this.assignedZonesSignatureList.length
      if (this.activeDocument.templateName && this.activeDocument.filePath && this.activeDocument.annotationsObject) {
        this.clearDeleteOnInput()
        this.updateDocumentImage()
      }
    }
    if(this.isDocumentLoaded) {
      this.totalPages = await this.$store.dispatch('document/getFilePageCount', { Value: this.uploadedfilePath })
    }
    if (this.activeDocument.zones && this.allZones === 0) {
      this.allZones = this.activeDocument.zones.length
    }
  },
  methods: {
    addRole() {
      if (this.addRoleInput === '') {
        return
      }
      let deletedRoleIndex = this.addedRoles.findIndex(role => role.value === '');
      if(deletedRoleIndex >= 0) {
        this.addedRoles[deletedRoleIndex].value = this.addRoleInput
      }
      else {
        this.addedRoles.push({value: this.addRoleInput})
      }
      this.addRoleInput = ''
      this.isDirty = true
    },
    addNewAnnotation(config) {
      if (this.isDocumentLoaded) {
        this.$store.dispatch('document/setAnnotationConfig', config)
        this.isDirty = true
      }
    },
    addSigner() {
      const user = {
        Email: this.addUsers,
        UserId: this.addUsers,
        FirstName: this.addSignerFirstName,
        LastName: this.addSignerLastName,
        FullName: `${this.addSignerFirstName} ${this.addSignerLastName}`,
        PhoneNumber: this.addSignerPhoneNumber,
      }
      if (this.editingUser) {
        this.addToList('update', user)
      } else {
        this.addToList('added', user)
      }
      this.editingUser = false
      this.showAddSigner = false
      this.addSignerFirstName = ''
      this.addSignerLastName = ''
      this.addSignerPhoneNumber = ''
    },
    async addToList(type, user) {
      let foundByUserId = false
      if (this.userList.find(u => u.UserId === user.UserId)) {
        user.disableButton = true
        foundByUserId = true
      }
      this.localAddedUserList = this.addedUserList
      if (type === 'added') {
        this.userList = foundByUserId ? this.userList.filter(u => u.UserId !== user.UserId) :
          this.userList.filter(u => u.FullName !== user.FullName && u.Email !== user.Email)
        this.localAddedUserList.push(user)
      } else if (type === 'update') {
        this.localAddedUserList = this.localAddedUserList.map(u => {
          if (u.Email === this.currentEditedEmail) {
            return user
          }
          return u
        })
      } else {
        this.localAddedUserList = this.localAddedUserList.filter(u => u.UserId !== user.UserId)
        this.userList.push(user)
        this.assignedUserSignatureList = this.assignedUserSignatureList.filter(u => u.userId !== user.UserId)
      }

      this.$store.dispatch('digitalSignature/setAddedUsersList', this.localAddedUserList)
      this.localUserList = await this.filteredValues(this.userList, this.addUsers)
      this.addUsers = ''
      this.showSearchList = false
      this.isDirty = true
    },
    annotationDeleted(id) {
      if (this.activeDocument.isTemplate) {
        this.assignedZonesSignatureList = this.assignedZonesSignatureList.filter(a => a.objectId !== id)
      } else {
        this.assignedUserSignatureList = this.assignedUserSignatureList.filter(a => a.objectId !== id)
      }
      this.isDirty = true
      this.allZones = this.allZones - 1
    },
    annotationUpdate() {
      this.isDirty = true
    },
    annotationCreated() {
      this.allZones = this.allZones + 1
    },
    cancel() { this.$router.push({ name: 'TheSignatureTemplates' }) },
    cancelAddSigner() {
      this.editingUser = false
      this.showAddSigner = false
      this.addSignerFirstName = ''
      this.addSignerLastName = ''
      this.addSignerPhoneNumber = ''
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    changeTemplateFile() {
      this.isReplacingFile = true
      this.toggleReplaceFileDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    async filteredValues(users, text) {
      const input = text.toLowerCase()
      if (!input) { return users }

      const returnList = users.filter(user => {
        const userNameTarget = user.Username?.toLowerCase()
        const foundUserName = userNameTarget?.indexOf(input) > -1

        const fullNameTarget = user.FullName?.toLowerCase()
        const foundFullName = fullNameTarget?.indexOf(input) > -1

        const emailTarget = user.Email?.toLowerCase()
        const foundEmail = emailTarget?.indexOf(input) > -1

        return foundUserName || foundFullName || foundEmail
      })

      return returnList
    },
    async clearDeleteOnInput() { await this.$store.dispatch('digitalSignature/setDeleteOnInput', false) },
    ellipsisButtonClicked(payload) {
      let indexDelete = []
      let assignedZonesSignatureFilterList = []
      switch (payload.buttonId) {
        case 'edit-signer':
          this.addSignerFirstName = payload.item.FirstName
          this.addSignerLastName = payload.item.LastName
          this.addUsers = payload.item.Email
          this.addSignerPhoneNumber = payload.item.PhoneNumber
          this.currentEditedEmail = payload.item.UserId
          this.editingUser = true
          this.showAddSigner = true
          break;
        case 'remove-signer':
          this.addToList('remove', payload.item)
          break;
        case 'edit-role':
          this.editRoleInput = payload.item.role
          this.editRoleValue = payload.item.role
          this.showEditRoleModal = true
          this.isDirty = true
          break;
        case 'remove-role':
          this.assignedZonesSignatureList.forEach(role => {
            if (role.sigDocTemplateRoleName === payload.item) {
              const index = this.assignedZonesSignatureList.findIndex(u => u.objectId === role.objectId);
              indexDelete.push(index)
            }
          })
          assignedZonesSignatureFilterList = this.assignedZonesSignatureList.filter(function(value, index){
              return indexDelete.indexOf(index) == -1;
          })
          this.assignedZonesSignatureList = assignedZonesSignatureFilterList
          this.addedRoles.splice(this.addedRoles.findIndex(role => role.value === payload.item), 1)
          this.updateActiveDocument()
          this.isDirty = true
          break;
        default:
          break;
      }
      this.ellipsisClose()
    },
    ellipsisClose() { this.showEllipsisMenu = false },
    ellipsisOpen(event, zone) {
      this.ellipsisClose()
      this.ellipsisRowItem = zone
      let displayY = event.pageY
      let baseSize = 0

      if (this.ellipsisButtonConfig && this.ellipsisButtonConfig.buttons) {
        baseSize = this.ellipsisButtonConfig.buttons.length * 40
      }

      if (displayY + baseSize > document.documentElement.scrollHeight) {
        displayY -= baseSize
      }

      this.ellipsisMenuTopStyle = `top:${displayY}px;`
      this.showEllipsisMenu = true
    },
    goToFirstPage() { this.goToPage(1) },
    goToLastPage() { this.goToPage(this.totalPages) },
    goToNextPage() {
      if ((this.currentPageNumber + 1) <= this.totalPages) {
        this.goToPage(this.currentPageNumber + 1)
      }
    },
    async goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPageNumber = pageNumber
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1)
      }
    },
    goToSettings() {
      if (this.uploadedFileName !== '' && this.uploadedfilePath !== '' && this.annotations) {
        this.updateActiveDocument()
        this.$store.dispatch('digitalSignature/setDeleteOnInput', true)
      }
      this.isDirty = false
      this.$router.push({ name: 'SignatureEditDocument', params: { documentId: this.editTemplateId !== 0 ? this.editTemplateId : 0 } })
    },
    loadAssignedRole() {
      this.newObjectSelected = true
      this.assignedRole = ''

      // See if the role-object association exists already
      const selectedRole = this.assignedZonesSignatureList?.find(r => r.objectId === this.associateZoneConfig.uniqueId)
      if (selectedRole) {
        const roleName = selectedRole.sigDocTemplateRoleName
        // See if we need to add the role associated with the object to the "ADDED ROLES" list. If so, add it.
        if (roleName !== '' && !this.addedRoles?.some(r => r.value === roleName)) {
          this.addedRoles.push(roleName)
        }
        this.assignedRole = roleName
      }
      // This is new, so if a default signer is selected, assign it to the zone
      else if (this.selectedSigner.value > 0) {
        this.assignedRole = this.addedRoles.find(x => x.value === this.selectedSigner.name)
        this.saveAssignedRole()
      }

      // nextTick is needed to avoid a race condition when changing the assigedUser value
      this.$nextTick(()=>{
        this.newObjectSelected = false
      })
    },
    loadAssignedUser() {
      this.newObjectSelected = true
      this.assignedUser = ''
      // See if the user-object association exists already
      if (this.assignedUserSignatureList?.some(u => u.objectId === this.associateZoneConfig.uniqueId)) {
        const index = this.assignedUserSignatureList.findIndex(u => u.objectId === this.associateZoneConfig.uniqueId);
        const { userId } = this.assignedUserSignatureList[index]
        const user = this.userList.find(u => u.UserId === userId) || this.addedUserList.find(u => u.UserId === userId)
        if (user) {
          // See if we need to add the user associated with the object to the "ADDED USERS" list. If so, add it and then
          if (!this.addedUserList?.some(u => u.UserId === userId)) {
            this.addToList('added', user)
          }
          if (!this.changedCurrentPage) {
            this.assignedUser = {
              name: user.FullName,
              value: userId,
            }
          }
        }
      }
      // This is new, so if a default signer is selected, assign it to the zone
      else if (this.selectedSigner.value > 0) {
        this.assignedUser =  this.addedUserListForDropList.find(x => x.value === this.selectedSigner.value)
        this.saveAssignedUser()
      }
      this.changedCurrentPage = false

      // nextTick is needed to avoid a race condition when changing the assigedUser value
      this.$nextTick(()=>{
        this.newObjectSelected = false
      })
    },
    // eslint-disable-next-line consistent-return
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        if (!file.type.includes('tif')
          && !file.type.includes('pdf')
          && this.$router.history.current.path === '/digitalSignature/newDocument') {
          this.snackbarTitle = 'Upload Failed'
          this.snackbarSubTitle = 'Signature Documents only supports these file types: .tif, .tiff, .pdf'
          this.snackbarImage = false
          this.showSnackbarBool = true
          this.isDocumentLoaded = true
          // The set timeout acts as a way to reset the upload display
          setTimeout(() => { this.isDocumentLoaded = false }, 500);
        } else {
          this.documentFileName = file.name
          let fileDataString = await toBase64(file)
          const indexOf = fileDataString.indexOf(';base64,')
          if (indexOf >= 0) {
            fileDataString = fileDataString.substring(indexOf + 8)
          }

          progress(true, 0, 1024);
          load(file.name)

          if (this.isReplacingFile && this.editTemplateId) {
            let ret
            if (this.activeDocument.isTemplate) {
              const payload = {
                name: this.documentFileName,
                documentTemplateID: this.editTemplateId,
                deleteZones: this.clearZonesOnChangeFile,
                fileData: fileDataString,
              }
              ret = await this.$store.dispatch('document/replaceSignatureDocumentTemplateFile', payload)
            } else {
              const payload = {
                name: this.documentFileName,
                filePath: this.uploadedfilePath,
                deleteZones: this.clearZonesOnChangeFile,
                fileData: fileDataString,
              }
              ret = await this.$store.dispatch('document/replaceSignatureDocumentFile', payload)
            }
            if (!ret.replaced) {
              if (!ret.guid) {
                this.showSnackbarBool = true
                this.snackbarTitle = 'Error attempting to replace file'
                this.snackbarSubTitle = ret.message
                this.snackbarImage = false
              } else {
                this.replaceMessage = ret.message
                this.replacementDocumentGUID = ret.guid
                this.showForceReplaceDialog = true
              }
            } else {
              this.showSnackbarBool = true
              this.snackbarTitle = 'Success'
              this.snackbarSubTitle = ret.message
              this.snackbarImage = true
              this.replacementDocumentGUID = ret.guid
              await this.reloadDocument()              
            }
            this.isReplacingFile = false
          } else {
            let payload = {
              name: file.name,
              fileData: fileDataString,
            }
            this.replacementDocumentGUID = await this.$store.dispatch('digitalSignature/UploadSignatureTemplateFile', payload)
            this.uploadedFileName = `${file.name} (TEMP)`
            this.reloadDocument()
          }

          this.isDocumentLoaded = true
          this.showUploadDialog = false
          this.isDirty = true
        }

        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      }
    },
    async reloadDocument() {
      if (this.clearZonesOnChangeFile || !this.activeDocument.editTemplateId) {
        this.assignedZonesSignatureList = []
        let tempDocument = cloneDeep(this.activeDocument)
        tempDocument.assignedZonesSignatureList = []
        tempDocument.zones = []
        this.$store.dispatch('digitalSignature/setActiveDocument', tempDocument)
        this.allZones = 0
      }
      if (this.editTemplateId > 0) {
        this.uploadedfilePath = null
        let response = await this.$store.dispatch('digitalSignature/getSignatureDocumentTemplate', this.editTemplateId)
        let tempDocument = cloneDeep(this.activeDocument)
        tempDocument.zones = response.zones
        tempDocument.assignedZonesSignatureList = response.zones
        this.$store.dispatch('digitalSignature/setActiveDocument', tempDocument)
        if (response?.zones?.length > 0) {
          this.allZones = response.zones.length
        } else {
          this.allZones = 0
        }
        this.uploadedfilePath = response.filePath
      } else {
        this.uploadedfilePath = null
        await this.uploadedfilePath //await here has the DocumentImage component reload
        this.uploadedfilePath = this.replacementDocumentGUID
      }
      let payload = {
        Value: this.uploadedfilePath,
      }
      this.currentPageNumber = 1
      this.totalPages = await this.$store.dispatch('document/getFilePageCount', payload)
    },
    replaceFileOKClick() {
      this.showUploadDialog = true
      this.toggleReplaceFileDialog()
    },
    async replaceTemplateModalOKClick() {
      if (this.activeDocument.isTemplate) {
        let payload = {
          guid: this.replacementDocumentGUID,
          documentTemplateID: this.editTemplateId,
        }
        await this.$store.dispatch('admin/signatureDocumentTemplateReplace', payload)
      } else {
        let payload = {
          oldFilePath: this.uploadedfilePath,
          newFilePath: this.replacementDocumentGUID,
        }
        await this.$store.dispatch('admin/signatureDocumentFileReplace', payload)
      }
      this.clearZonesOnChangeFile = true
      await this.reloadDocument()
      this.toggleForceReplaceDialog()
    },
    saveAssignedRole() {
      if (this.assignedZonesSignatureList.some(r => r.objectId === this.associateZoneConfig.uniqueId)) {
          const index = this.assignedZonesSignatureList.findIndex(u => u.objectId === this.associateZoneConfig.uniqueId);
          this.assignedZonesSignatureList.splice(index, index >= 0 ? 1 : 0);
      }
      if (this.assignedRole && this.assignedRole !== '' && this.associateZoneConfig) {
        this.assignedZonesSignatureList.push({
          objectId: this.associateZoneConfig.uniqueId,
          sigDocTemplateRoleName: this.assignedRole.value,
          subType: this.associateZoneConfig.subType,
          pageNumber: this.currentPageNumber,
          bottomAlign: this.localBottomAlign,
          isRequired: this.localIsRequired,
        })
      }
      this.updateActiveDocument()
    },
    saveAssignedUser() {
      if (this.assignedUserSignatureList.some(u => u.objectId === this.associateZoneConfig.uniqueId)) {
        const index = this.assignedUserSignatureList.findIndex(u => u.objectId === this.associateZoneConfig.uniqueId);
        this.assignedUserSignatureList.splice(index, index >= 0 ? 1 : 0);
      }
      if (this.assignedUser && this.assignedUser !== '' && this.associateZoneConfig) {
        this.assignedUserSignatureList.push({
          objectId: this.associateZoneConfig.uniqueId,
          userId: this.assignedUser.value,
          subType: this.associateZoneConfig.subType,
          pageNumber: this.currentPageNumber,
          bottomAlign: this.localBottomAlign,
          isRequired: this.localIsRequired,
        })
      }
      this.updateActiveDocument()
    },
    toggleReplaceFileDialog() {
      this.showReplaceFileDialog = !this.showReplaceFileDialog
    },
    toggleForceReplaceDialog() {
      this.showForceReplaceDialog = !this.showForceReplaceDialog
    },
    toggleUploadDialog: function () {
      this.showUploadDialog = !this.showUploadDialog
    },
    updateActiveDocument() {
      let payload = this.activeDocument
      payload.templateName = this.uploadedFileName
      payload.filePath = this.uploadedfilePath
      payload.totalPages = this.totalPages
      payload.annotationsObject = this.annotations
      payload.assignedUserSignatureList = this.assignedUserSignatureList
      payload.isTemplate = this.activeDocument.isTemplate
      payload.editTemplateId = this.editTemplateId
      payload.roles = this.activeDocument.roles
      if (this.activeDocument.isTemplate) {
        payload.assignedZonesSignatureList = this.assignedZonesSignatureList
      } else {
        payload.assignedUserSignatureList = this.assignedUserSignatureList
      }
      this.$store.dispatch('digitalSignature/setActiveDocument', payload)
    },
    async setDeleteOnInput(key) {
      if (key.code === 'Backspace') {
        await this.$store.dispatch('digitalSignature/setDeleteOnInput', true)
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async updateDocumentImage() {
      this.uploadedFileName = this.activeDocument.templateName
      this.uploadedfilePath = this.activeDocument.filePath
      this.isDocumentLoaded = this.uploadedfilePath?.trim().length > 0
      this.assignedUserSignatureList = this.activeDocument.assignedUserSignatureList ?? []
      this.assignedZonesSignatureList = [...this.activeDocument.assignedZonesSignatureList] ?? []
      if (this.activeDocument.isTemplate) {
        this.loadAssignedRole()
      } else {
        this.loadAssignedUser()
      }
    },
    async updateRole() {
      const index = this.addedRoles.findIndex(element => element.value === this.ellipsisRowItem)
      this.addedRoles[index].value = this.editRoleInput
      this.showEditRoleModal = false

      this.assignedZonesSignatureList.forEach(role => {
        if (role.sigDocTemplateRoleName === this.editRoleValue) {
          role.sigDocTemplateRoleName = this.editRoleInput
        }
      })

      // Update the sigDocTemplateRoleName here so that the edit is reflected in the '/digitalSignature/editDocument/{documentId}' view
      const activeDocumentClone = cloneDeep(this.activeDocument)
      const editedRoleIndex = activeDocumentClone.roles.findIndex(role => role.sigDocTemplateRoleName === this.editRoleValue)
      activeDocumentClone.roles[editedRoleIndex].sigDocTemplateRoleName = this.editRoleInput
      await this.$store.dispatch('digitalSignature/setActiveDocument', activeDocumentClone)

      this.updateActiveDocument()
    },
    async userSearchList(input) {
      this.localUserList = this.userList.filter(u => {
        return !this.addedUserList.find(au => au.UserId === u.UserId)
      })

      if (input) {
        this.showSearchList = input.length > 2
        this.localUserList = await this.filteredValues(this.localUserList, input)
      } else {
        this.showSearchList = false
      }
    },
    zoneLabel(label) { return this.displayZoneType ? `${label} TO ${this.displayZoneType.toLocaleUpperCase()}` : label },
    zoomIn() {
      if (this.zoomValue > 3) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'WARNING'
        this.snackbarSubTitle = 'Unable to zoom past this point.'
        this.snackbarImage = false
      } else {
        this.$store.dispatch('document/setZoomValue', true)
      }
    },
    zoomOut() {
      if (this.zoomValue === 1) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'WARNING'
        this.snackbarSubTitle = 'Unable to zoom past this point.'
        this.snackbarImage = false
      } else {
        this.$store.dispatch('document/setZoomValue', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';
  #include-time-checkbox {
    padding-top: 30px;
  }

  .innerDiv {
    padding-left: 5px;
    padding-top: 10px;
  }

  .divider {
    border-bottom: solid 1px $divider-light-grey;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    padding-bottom: 5px;
  }

  .left-sidebar {
    width: 256px;
    height: 100%;
    box-shadow: 1px 0 0 0 $grey-100;
    display: flex;
    flex-direction: column;

    .sidebar-signer {
      margin: 20px 12px 4px 8px;
    }

    .sidebar-button-header {
      margin: 20px 8px;
      font-weight: bold;
      font-size: 16px;
    }
    .sidebar-button {
      width: 224px;
      height: 48px;
      border-radius: 8px;
      margin: 4px 8px;
      background-color: $grey-75;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .sidebar-button:hover {
      background-color: #e7ebf0;
    }
    .button-icon {
      margin: 0px 10px 0px 15px;
      display: flex;
      align-items: center;
    }
  }

  .control-box {
    display: flex;
    align-items: center;
  }
  .main-content {
    height: calc(100vh - 276px);
  }
  .middle-section {
    background-color: $grey-75;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: calc(100vw - 880px);

    .document-upload {
      width: 600px;
      height: calc(100vh - 260px);
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(22, 23, 24, 0.08);
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .upload-container {
      width: 100%;
      height: 100%;
    }
  }

  .right-sidebar {
    width: 300px;
    height: 100%;
    box-shadow: -1px 0 0 0 $grey-100;
    .add-users {
      display: flex;
      padding: 24px 12px;
      flex-direction: column;
    }
    .added-users {
        margin: 24px 0px;
        width: 100%;
        max-height: 803px;
        overflow-y: auto;
        padding: 10px;
    }
    .added-users-title {
      font-family: $font-family-medium;
      font-size: 12px;
      color: $grey-400;
      padding-left: 10px;
    }
    .added-users-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .added-users-content span {
      margin: 10px 0px;
      text-align: center;
    }

    .users-heading {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: $grey-700;
    }
    .users-subheading {
      font-size: 14px;
      text-align: center;
      color: $grey-400;
    }
    .panel-component-bottom-padding {
      padding-bottom: 6px;
    }
  }
  .user-search {
    position: relative;
    height: 0px;
    width: 0px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(22, 23, 24, 0.08);
    background-color: $white;
  }
  .user-list-container {
    width: 304px;
    max-height: 340px;
    overflow-y: auto;
    border-radius: 8px;
    z-index: 6;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11), 0 4px 8px 0 rgba(0, 0, 0, 0.13);
    background-color: $white;
    position: absolute;
  }
  .user-list-item {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-list-item:hover {
    border-radius: 10px;
    background-color: $grey-200;
    cursor: pointer;
  }
  .user-list-item-name {
    width: 80%;
    text-align: left;
    align-content: center;
    padding-left: 10px;
  }
  .added-users-container {
    width: 100%;
    margin: 0px;
    padding: 0px 15px 15px 15px;
  }
  .added-users-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  .added-users-info {
    width: 80%;
  }
  .added-users-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .buttonDisabled {
    cursor: not-allowed !important;
  }
  .buttonSelected {
    border: solid 1px;
  }
  .zoom-box {
    background-color: $white;
    min-width: 141px;
    min-height: 40px;
    position: absolute;
    bottom: 66px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(22, 23, 24, 0.08);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .zoom-symbol {
    font-size: 24px;
    cursor: pointer;
    :hover {
      background-color: $grey-100;
      border-radius: 11px;
    }
  }
  .empty-add-signers-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 29px;
  }
  #add-signer-icon-and-text {
    display: flex;
    align-items: center;
  }
  #add-external-signer-container{
    width: 588px;
    height: 450px;
  }
  .add-signer-modal-spacer{
    margin-top: 16px;
  }
  #add-role-button {
    border-radius: 8px;
    background-color: $grey-50;
    margin: 8px 0px 0px 0px;
    float: right;
    text-transform: none
  }
  #zone-details-title {
    @include SubHeadline;
    display: flex;
    align-self: flex-start;
    font-size: 18px;
    margin: 0px 0px 20px 0px;
  }
  @media screen and (max-width: 1600px) {
    .middle-section {
      width: calc(100vw - 540px);
    }
    .right-sidebar {
        .added-users-title {
          padding-left: 1px;
        }
    }
    .left-sidebar {
      width: 190px;
      .sidebar-button {
            width: 160px;
        }
    }
    .user-list-container {
      width: 274px;
    }
  }

  @media screen and (max-width: 1108px) {
    .left-sidebar {
        width: 90px;
        .sidebar-button {
            width: 50px;
            color: transparent
        }
    }
    .document-upload {
      width: 50%;
    }
    .upload-container {
      width: 60%;
    }
  }

  #upload-new-file-dialog {
    .align-right {
      text-align: right;
      padding: 5px 0px 10px 374px;
    }
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
